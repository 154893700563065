/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./Routes/home";
import "./responsive.css";
import Game from "./Routes/game";
import Navbar from "./Components/navbarSuperAdmin";
import UserPanel from "./Routes/userPanel";
import ProfitLoss from "./Routes/profitLoss";
import NotFound from "./Routes/404";
import RegisterPage from "./Routes/register";
import LoginPage from "./Routes/login";
import FundReq from "./Routes/fundReq";
import BetHistory from "./Routes/betHistory";
import UserLog from "./Routes/userLog";
import MarketAnalysis from "./Routes/marketAnalysis";
import ProtectedRoute from "./protectedRoute";
import NavbarAdmin from "./Components/navbarAdmin";
import BetHistoryAdmin from "./Routes/admin/betHistory";
import MarketAnalysisAdmin from "./Routes/admin/marketAnalysis";
import GameAdmin from "./Routes/admin/game";
import UserPanelAdmin from "./Routes/admin/userPanel";
import FundReqAdmin from "./Routes/admin/fundReq";
import NavbarMaster from "./Components/navbarMaster";
import GenerateGame from "./Routes/generateGame";
import WithdrawReq from "./Routes/withdrawReq";
import WithdrawHistory from "./Routes/withdrawHistory";
import CleanWithdrawHistory from "./Routes/clean";
import FundAddHistory from "./Routes/fundAddHistory";
import WithdrawReqAdmin from "./Routes/admin/withdrawReq";
import WithdrawHistoryAdmin from "./Routes/admin/withdrawHistory";
import FundAddHistoryAdmin from "./Routes/admin/fundAddHistory";
import WithdrawReqMaster from "./Routes/master/withdrawReq";
import FundAddHistoryMaster from "./Routes/master/fundAddHistory";
import BetHistoryMaster from "./Routes/master/betHistory";
import FundReqMaster from "./Routes/master/fundReq";
import AdditionalInnerUser from "./Components/additionalInnerUser";
import ProfitLossAdmin from "./Routes/admin/profitLossAdmin";
import ProfitLossMaster from "./Routes/master/profitLossMaster";
import SignOut from "./Routes/signout";
import UserPanelMaster from "./Routes/master/userPanel";
import MarketAnalysisMaster from "./Routes/master/marketAnalysis";
import FirebasePaymentError from "./firebasePaymentError";
import FilterBetLogsPage from "./remobebetlog";

function App() {
  return (
    <Routes >
      {/* <Route path="/" element={<FirebasePaymentError />}></Route> */}
      <Route path='/' element={<ProtectedRoute />}>



        <Route path='/super-admin' element={<Navbar />}>
          <Route path='' element={<Home />}></Route>
          <Route path='withdraw-request' element={<WithdrawReq />}></Route>
          <Route path='game' element={<Game />}></Route>
          <Route path='users' element={<UserPanel />}></Route>
          <Route path='profit-loss' element={<ProfitLoss />}></Route>
          <Route path='fund-req' element={<FundReq />}></Route>
          <Route path='user-log' element={<UserLog />}></Route>
          <Route path='bet-history' element={<BetHistory />}></Route>
          <Route path='market-analysis' element={<MarketAnalysis />}></Route>
          <Route path='withdraw-history' element={<WithdrawHistory />}></Route>
          <Route path='fund-add-history' element={<FundAddHistory />}></Route>
          <Route path='clean' element={<CleanWithdrawHistory />}></Route>
          <Route path=':username/:type' element={<AdditionalInnerUser />}></Route>
        </Route>
        <Route path='/admin' element={<NavbarAdmin />} >
          <Route path='' element={<Home />}></Route>
          <Route path='withdraw-request' element={<WithdrawReqAdmin />}></Route>
          <Route path='fund-add-history' element={<FundAddHistoryAdmin />}></Route>
          <Route path='withdraw-history' element={<WithdrawHistoryAdmin />}></Route>
          <Route path='game' element={<GameAdmin />}></Route>
          <Route path='bet-history' element={<BetHistoryAdmin />}></Route>
          <Route path='users' element={<UserPanelAdmin />}></Route>
          <Route path='market-analysis' element={<MarketAnalysisAdmin />}></Route>
          <Route path='fund-req' element={<FundReqAdmin />}></Route>
          <Route path=':username/:type' element={<AdditionalInnerUser />}></Route>
          <Route path='profit-loss' element={<ProfitLossAdmin />}></Route>
        </Route>
        <Route path='/master' element={<NavbarMaster />} >
          <Route path='' element={<Home />}></Route>
          <Route path='withdraw-request' element={<WithdrawReqMaster />}></Route>
          <Route path='fund-add-history' element={<FundAddHistoryMaster />}></Route>
          <Route path='withdraw-history' element={<WithdrawReqMaster />}></Route>
          <Route path='game' element={<GameAdmin />}></Route>
          <Route path='bet-history' element={<BetHistoryMaster />}></Route>
          <Route path='users' element={<UserPanelMaster />}></Route>
          <Route path='market-analysis' element={<MarketAnalysisMaster />}></Route>
          <Route path='fund-req' element={<FundReqMaster />}></Route>
          <Route path=':username/:type' element={<AdditionalInnerUser />}></Route>
          <Route path='profit-loss' element={<ProfitLossMaster />}></Route>

        </Route>

      </Route>


      {/* cron job link  */}
      <Route path='generate-game' element={<GenerateGame />}></Route>
      <Route path='signout' element={<SignOut />}></Route>
      <Route path="remove" element={<FilterBetLogsPage />}></Route>
      <Route path='/register' element={<RegisterPage />}></Route>
      <Route path='/login' element={<LoginPage />}></Route>

      <Route path='*' element={<NotFound />}></Route>
    </Routes>
  );
}

export default App;
