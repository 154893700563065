/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-key */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable quotes */

import React, { useState } from 'react';
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { db } from './utils/firebase';

const FilterBetLogsPage = () => {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const filterBetLogsByDate = async (targetDate) => {
        setLoading(true); // Start loading
        setError(null); // Clear any previous errors
        setLogs([]); // Reset logs

        try {
            const userProfileCollection = collection(db, 'userProfile');
            const userProfileDocs = await getDocs(userProfileCollection);

            const newLogs = []; // Temporary logs storage

            // Iterate over each user document
            for (const userDoc of userProfileDocs.docs) {
                const userData = userDoc.data();

                // Check if betLog exists and is an array
                if (userData.betLog && Array.isArray(userData.betLog)) {
                    const filteredBetLog = userData.betLog.filter(bet => bet.date === targetDate);

                    // Update the document with the filtered betLog
                    const userRef = doc(db, 'userProfile', userDoc.id);
                    await updateDoc(userRef, { betLog: filteredBetLog });

                    // Add to logs
                    newLogs.push(`Updated betLog for user: ${userDoc.id}, remaining bets: ${filteredBetLog.length}`);
                } else {
                    newLogs.push(`No betLog found for user: ${userDoc.id}`);
                }
            }

            setLogs(newLogs); // Set the logs to be displayed
            setLoading(false); // Stop loading
        } catch (err) {
            setError('Error filtering betLogs: ' + err.message);
            setLoading(false); // Stop loading in case of error
        }
    };

    // Handle button click to start filtering
    const handleFilter = () => {
        filterBetLogsByDate('08/10/2024');
    };

    return (
        <div>
            <h1>Filter Bet Logs by Date</h1>
            <button onClick={handleFilter} disabled={loading}>
                {loading ? 'Processing...' : 'Start Filtering'}
            </button>

            {error && <p style={{ color: 'red' }}>{error}</p>}

            <div>
                <h2>Logs:</h2>
                {logs.length === 0 && !loading && <p>No logs to show yet.</p>}
                {logs.map((log, index) => (
                    <p key={index}>{log}</p>
                ))}
            </div>
        </div>
    );
};

export default FilterBetLogsPage;
