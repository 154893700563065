/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useEffect, useState, useCallback } from "react";
import { auth, db } from "./../utils/firebase";
import { getDocs, collection } from "firebase/firestore";
import moment from "moment";

const ProfitLoss = () => {
    const [profitLossData, setProfitLossData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [formattedData, setFormattedData] = useState([]);
    const [extendedData, setExtendedData] = useState([]);
    const [extendedData2, setExtendedData2] = useState([]);
    const [loading, setLoading] = useState(false);

    const formatDate = (date) => {
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, "0");
        const month = (d.getMonth() + 1).toString().padStart(2, "0");
        const year = d.getFullYear();
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        // Set initial start and end dates to today
        const today = new Date();
        const formattedStartDate = formatDate(today);
        const formattedEndDate = formatDate(today);
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);

        // Load data for today
        // const loadData = async () => {
        //     const userProfileMap = await fetchUserEmails();
        //     await fetchBetsForUsers(userProfileMap);
        // };

        // loadData();
    }, []);

    const fetchUserEmails = useCallback(async () => {
        const userProfileCollectionRef = collection(db, "userProfile");
        const userProfileQuerySnapshot = await getDocs(userProfileCollectionRef);

        const userProfileMap = {};

        userProfileQuerySnapshot.forEach((doc) => {
            const userData = doc.data();
            userProfileMap[userData.emailId] = userData.type;
        });

        // console.log(userProfileMap);
        return userProfileMap;
    }, []);

    const fetchBetsForUsers = useCallback(async (userProfileMap) => {
        const betsCollectionRef = collection(db, "bets");
        const betsQuerySnapshot = await getDocs(betsCollectionRef);

        const emailMap = {};
        const adminBetsMap = {};
        const masterBetsMap = {};

        betsQuerySnapshot.forEach((doc) => {
            const betData = doc.data();
            const userEmail = betData.user;
            const masterEmail = betData.masterEmail;
            const adminEmail = betData.adminEmail;
            const amount = betData.amount;
            const number = betData.number;
            const status = betData.status;
            const date = moment(betData.date, "DD/MM/YYYY");
            const superadmin = betData.superadmin || "NOT THERE"; // Ensure default value if not present

            // Debug logging to check superadmin value
            // console.log(`Superadmin for user ${userEmail}: ${superadmin}`);

            const start = moment(startDate);
            const end = moment(endDate);

            // Check if the bet date is within the specified range
            if (date.isBetween(start, end, undefined, "[]")) {
                if (userEmail) {
                    const userType = userProfileMap[userEmail];
                    const key = `${userEmail}_${userType}`;

                    // Initialize the entry in emailMap if it doesn't exist
                    if (!emailMap[key]) {
                        emailMap[key] = {
                            email: userEmail,
                            type: userType,
                            amountPlayed: 0,
                            amountWon: 0,
                            profitLoss: 0,
                            masterEmail,
                            adminEmail
                        };
                    }
                    // console.log(userEmail + superadmin);
                    // Update superadmin in emailMap entry if it exists
                    emailMap[key].superadmin = superadmin;

                    // Calculate amount won based on bet status and number
                    let amountWon = 0;
                    if (status === "win") {
                        if (number.toString().length === 1) {
                            amountWon = amount * 9;
                        } else if (number.toString().length === 3) {
                            amountWon = amount * 100;
                        }
                    }

                    // Update amount played and amount won for the user
                    emailMap[key].amountPlayed += amount;
                    emailMap[key].amountWon += amountWon;

                    // Update master bets if masterEmail is present
                    if (masterEmail) {
                        if (!masterBetsMap[masterEmail]) {
                            masterBetsMap[masterEmail] = {
                                email: masterEmail,
                                type: "Master",
                                amountPlayed: 0,
                                amountWon: 0,
                                profitLoss: 0,
                                masterEmail
                            };
                        }
                        masterBetsMap[masterEmail].amountPlayed += amount;
                        masterBetsMap[masterEmail].amountWon += amountWon;
                    }

                    // Update admin bets if adminEmail is present
                    if (adminEmail) {
                        if (!adminBetsMap[adminEmail]) {
                            adminBetsMap[adminEmail] = {
                                email: adminEmail,
                                type: "Admin",
                                amountPlayed: 0,
                                amountWon: 0,
                                profitLoss: 0,
                                adminEmail
                            };
                        }
                        adminBetsMap[adminEmail].amountPlayed += amount;
                        adminBetsMap[adminEmail].amountWon += amountWon;
                    }
                }
            }
        });

        // Calculate profit/loss for each user, master, and admin
        Object.keys(emailMap).forEach((key) => {
            emailMap[key].profitLoss = emailMap[key].amountPlayed - emailMap[key].amountWon;
        });

        Object.keys(masterBetsMap).forEach((key) => {
            masterBetsMap[key].profitLoss = masterBetsMap[key].amountPlayed - masterBetsMap[key].amountWon;
        });

        Object.keys(adminBetsMap).forEach((key) => {
            adminBetsMap[key].profitLoss = adminBetsMap[key].amountPlayed - adminBetsMap[key].amountWon;
        });

        // Combine all formatted data into a single array
        const formattedData = [
            ...Object.values(emailMap),
            ...Object.values(masterBetsMap),
            ...Object.values(adminBetsMap),
        ];

        // Set state with the formatted data
        setFormattedData(formattedData);
        setProfitLossData(formattedData);
    }, [startDate, endDate]);


    const handleSearch = () => {
        if (startDate && endDate) {
            const loadData = async () => {
                setLoading(true);  // Show loading message

                const userProfileMap = await fetchUserEmails();
                await fetchBetsForUsers(userProfileMap);

                setLoading(false); // Hide loading message
            };

            loadData();
        }
    };


    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleExtendData = async (emailid, typeid) => {
        try {
            // Reference to the Firestore collection
            const userProfileCollectionRef = collection(db, "userProfile");
            // Fetch all documents in the collection
            const userProfileQuerySnapshot = await getDocs(userProfileCollectionRef);

            const emails = [];

            // Iterate through each document to gather email IDs based on typeid
            userProfileQuerySnapshot.forEach((doc) => {
                const userData = doc.data();

                if (typeid === "Master" && userData.masterEmail === emailid) {
                    emails.push(userData.emailId);
                } else if (typeid === "Admin" && userData.adminEmail === emailid) {
                    emails.push(userData.emailId);
                }
            });

            console.log(emails);

            // Filter and reduce formattedData based on gathered emails
            const extendedData = formattedData
                .filter((entry) => emails.includes(entry.email))
                .reduce((acc, entry) => {
                    if (!acc[entry.email]) {
                        acc[entry.email] = { ...entry };
                    } else {
                        acc[entry.email].amountPlayed += entry.amountPlayed;
                        acc[entry.email].amountWon += entry.amountWon;
                        acc[entry.email].profitLoss += entry.profitLoss;
                    }
                    return acc;
                }, {});

            console.log(formattedData);

            // Convert the object to an array and apply additional filtering
            const extendedDataArray = Object.values(extendedData).filter(entry =>
                !(entry.type === "User" && entry.masterEmail !== "")
            );

            // Update the state with the filtered extended data array
            setExtendedData(extendedDataArray);
            setExtendedData2([]);

            // Log the filtered extended data array for debugging
            console.log(extendedDataArray);
        } catch (error) {
            // Handle errors
            console.error("Error fetching or processing data:", error);
        }
    };


    const handleExtendData2 = async (emailid, typeid) => {
        const userProfileCollectionRef = collection(db, "userProfile");
        const userProfileQuerySnapshot = await getDocs(userProfileCollectionRef);

        const emails = [];

        userProfileQuerySnapshot.forEach((doc) => {
            const userData = doc.data();
            console.log(userData);

            if (typeid === "Master" && userData.masterEmail === emailid) {
                emails.push(userData.emailId);
            } else if (typeid === "Admin" && userData.adminEmail === emailid) {
                emails.push(userData.emailId);
            }
        });

        const extendedData = formattedData
            .filter((entry) => emails.includes(entry.email))
            .reduce((acc, entry) => {
                if (!acc[entry.email]) {
                    acc[entry.email] = { ...entry };
                } else {
                    acc[entry.email].amountPlayed += entry.amountPlayed;
                    acc[entry.email].amountWon += entry.amountWon;
                    acc[entry.email].profitLoss += entry.profitLoss;
                }
                return acc;
            }, {});

        const extendedDataArray = Object.values(extendedData);
        setExtendedData2(extendedDataArray);
    };

    const filteredData = formattedData.filter((entry) =>
        entry.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const userFilteredData = filteredData.filter(entry => entry.type === "User");
    // console.log(filteredData);

    // Calculate totals for the initial table
    const totalInitialAmountPlayed = filteredData.reduce((total, entry) => total + entry.amountPlayed, 0);
    const totalInitialAmountWon = filteredData.reduce((total, entry) => total + entry.amountWon, 0);
    const totalInitialProfitLoss = filteredData.reduce((total, entry) => total + entry.profitLoss, 0);

    // Calculate totals for the USER type
    const totalUserAmountPlayed = userFilteredData.reduce((total, entry) => total + entry.amountPlayed, 0);
    const totalUserAmountWon = userFilteredData.reduce((total, entry) => total + entry.amountWon, 0);
    const totalUserProfitLoss = userFilteredData.reduce((total, entry) => total + entry.profitLoss, 0);

    // Calculate totals for the extended table
    const totalExtendedAmountPlayed = extendedData.reduce((total, entry) => total + entry.amountPlayed, 0);
    const totalExtendedAmountWon = extendedData.reduce((total, entry) => total + entry.amountWon, 0);
    const totalExtendedProfitLoss = extendedData.reduce((total, entry) => total + entry.profitLoss, 0);

    // Calculate totals for the extended table
    const totalExtendedAmountPlayed2 = extendedData2.reduce((total, entry) => total + entry.amountPlayed, 0);
    const totalExtendedAmountWon2 = extendedData2.reduce((total, entry) => total + entry.amountWon, 0);
    const totalExtendedProfitLoss2 = extendedData2.reduce((total, entry) => total + entry.profitLoss, 0);

    // Initialize a map to aggregate data by email
    const combinedDataMap = {};

    // Populate the combinedDataMap with aggregated data
    filteredData.forEach((entry) => {
        const key = entry.email;

        if (!combinedDataMap[key]) {
            combinedDataMap[key] = { ...entry }; // Create a new entry if not exists
        } else {
            // Aggregate the data for Masters and Admins
            if (entry.type === "Master" || entry.type === "Admin") {
                combinedDataMap[key].amountPlayed += entry.amountPlayed;
                combinedDataMap[key].amountWon += entry.amountWon;
                combinedDataMap[key].profitLoss += entry.profitLoss;
            }
        }
    });

    // Convert combinedDataMap values into an array for rendering
    const combinedData = Object.values(combinedDataMap);
    return (
        <div>
            <div className="toppart-diff">
                <div className="section--294851">
                    <label htmlFor="start-date">
                        <p>From Date</p>
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </label>
                    <label htmlFor="end-date">
                        <p>To Date</p>
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </label>
                </div>
                <div className="section-5538721">
                    <button
                        className="submitSearchQueryProfitLoss"
                        onClick={handleSearch}
                    >
                        Search by Date
                    </button>
                </div>
            </div>
            <div className="table-wrapper">
                <input
                    type="text"
                    placeholder="Search by Username"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                />
                {loading && <h1>Loading...</h1>}
                <table className="fl-table">

                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Type</th>
                            <th>Total Played</th>
                            <th>Total Won</th>
                            <th>P/L</th>
                        </tr>
                    </thead>
                    <tbody>
                        {combinedData.map((entry, index) => (
                            (entry.type === "User" && entry.superadmin === auth.currentUser.email) || entry.type !== "User" && entry.type !== "Master" ? (
                                <tr key={index}>
                                    <td style={{ cursor: "pointer" }} onClick={() => handleExtendData(entry.email, entry.type)}>
                                        {entry.email.replace("@gmail.com", "")}
                                    </td>
                                    <td>{entry.type}</td>
                                    <td>{entry.amountPlayed}</td>
                                    <td>{entry.amountWon}</td>
                                    <td>{entry.profitLoss}</td>
                                </tr>
                            ) : null
                        ))}


                        <tr>
                            <td colSpan="2"><strong>User Totals</strong></td>
                            <td><strong>{totalUserAmountPlayed}</strong></td>
                            <td><strong>{totalUserAmountWon}</strong></td>
                            <td><strong>{totalUserProfitLoss}</strong></td>
                        </tr>
                    </tbody>
                </table>
                {extendedData.length > 0 && (
                    <div className="extended-table-wrapper">
                        <h3 style={{ textAlign: "center", margin: "10px" }}>Extended Data</h3>
                        <table className="fl-table">
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Type</th>
                                    <th>Total Played</th>
                                    <th>Total Won</th>
                                    <th>P/L</th>
                                </tr>
                            </thead>
                            <tbody>
                                {extendedData.map((entry, index) => (
                                    // (entry.type !== "User" ? (
                                    <tr onClick={() => handleExtendData2(entry.email, entry.type)} key={index}>
                                        <td>{entry.email.replace("@gmail.com", "")}</td>
                                        <td>{entry.type}</td>
                                        <td>{entry.amountPlayed}</td>
                                        <td>{entry.amountWon}</td>
                                        <td>{entry.profitLoss}</td>
                                    </tr>
                                    // ) : null)
                                ))}
                                <tr>
                                    <td colSpan="2"><strong>Totals</strong></td>
                                    <td><strong>{totalExtendedAmountPlayed}</strong></td>
                                    <td><strong>{totalExtendedAmountWon}</strong></td>
                                    <td><strong>{totalExtendedProfitLoss}</strong></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
                {extendedData2 && extendedData2.length > 0 && (
                    <div className="extended-table-wrapper">
                        <h3 style={{ textAlign: "center", margin: "10px" }}>Extended Data</h3>
                        <table className="fl-table">
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Type</th>
                                    <th>Total Played</th>
                                    <th>Total Won</th>
                                    <th>P/L</th>
                                </tr>
                            </thead>
                            <tbody>
                                {extendedData2 && extendedData2.length > 0 ? (
                                    extendedData2.map((entry, index) => (
                                        <tr key={index}>
                                            <td>{entry.email.replace("@gmail.com", "")}</td>
                                            <td>{entry.type}</td>
                                            <td>{entry.amountPlayed}</td>
                                            <td>{entry.amountWon}</td>
                                            <td>{entry.profitLoss}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" style={{ textAlign: "center" }}>
                                            No Data Available
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td colSpan="2"><strong>Totals</strong></td>
                                    <td><strong>{totalExtendedAmountPlayed2 || 0}</strong></td>
                                    <td><strong>{totalExtendedAmountWon2 || 0}</strong></td>
                                    <td><strong>{totalExtendedProfitLoss2 || 0}</strong></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}


            </div>
        </div>
    );
};

export default ProfitLoss;
