/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-key */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable quotes */

import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, deleteDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';

const BetHistory = () => {
    const [betHistory, setBetHistory] = useState([]);
    const [sortBy, setSortBy] = useState('time'); // Default sorting by time
    const [sortOrder, setSortOrder] = useState('desc'); // Initial sorting order as descending
    const [emailFilter, setEmailFilter] = useState('');
    const [dateFilter, setDateFilter] = useState('');

    useEffect(() => {
        const today = new Date();
        const formattedToday = formatDate(today);
        setDateFilter(formattedToday);
        console.log('Today’s date:', formattedToday);
    }, []);

    useEffect(() => {
        fetchBetHistory();
    }, [dateFilter, sortBy, sortOrder, emailFilter]); // Include sortBy and sortOrder in the dependency array

    const fetchBetHistory = async () => {
        try {
            let q = query(collection(db, 'bets'));

            // Apply email filter if entered
            if (emailFilter) {
                q = query(q, where('user', '==', emailFilter));
            }

            // Apply date filter if entered
            if (dateFilter) {
                // Query Firestore with the formatted date
                q = query(q, where('date', '==', dateFilter));
            }

            const querySnapshot = await getDocs(q);
            const betHistoryData = [];

            querySnapshot.forEach(doc => {
                const betData = doc.data();
                console.log(betData)
                betHistoryData.push({
                    id: doc.id,
                    date: betData.date,
                    time: convertTo24Hour(betData.time), // Convert time to 24-hour format
                    number: betData.number,
                    amount: betData.amount,
                    email: betData.user,
                    uid: betData.uid,
                    gameName: betData.gameName,
                    fatafatName: betData.fatafatName
                });
            });

            setBetHistory(sortBetHistory(betHistoryData, sortBy, sortOrder)); // Sort data upon fetching
        } catch (error) {
            console.error('Error fetching bet history:', error);
        }
    };

    const sortBetHistory = (betHistoryData, sortByField, order) => {
        return betHistoryData.sort((a, b) => {
            if (sortByField === 'email') {
                return order === 'asc' ? a.email.localeCompare(b.email) : b.email.localeCompare(a.email);
            } else if (sortByField === 'date') {
                return order === 'asc' ? new Date(a.date) - new Date(b.date) : new Date(b.date) - new Date(a.date);
            } else if (sortByField === 'time') {
                const [hoursA, minutesA] = a.time.split(':').map(Number);
                const [hoursB, minutesB] = b.time.split(':').map(Number);
                const timeA = hoursA * 60 + minutesA;
                const timeB = hoursB * 60 + minutesB;
                return order === 'asc' ? timeA - timeB : timeB - timeA;
            }
            return 0;
        });
    };

    // Function to format the date to dd/mm/yyyy
    const formatDate = (date) => {
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, '0');
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const year = d.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handleCancelBet = async (betId, email, amount) => {
        const formatDateCancel = (date) => {
            const [day, month, year] = date.split('/');
            return `${day}-${month}-${year}`;
        };

        console.log(`Canceling bet with ID: ${betId}`);

        let gamedate;
        let gameid;
        let declaredBool;

        try {
            const docRef = doc(db, 'bets', betId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                console.log('Bet document data:', docSnap.data());
                gamedate = formatDateCancel(docSnap.data().date);
                gameid = docSnap.data().gameID;
                console.log(gamedate, gameid);
            } else {
                console.log(`Bet ID ${betId} - No such document!`);
                return;
            }
        } catch (error) {
            console.error('Error fetching bet document:', error);
            return;
        }

        try {
            const declareData = doc(db, gamedate, gameid);
            const declareSnap = await getDoc(declareData);

            if (declareSnap.exists()) {
                console.log('Declare document data:', declareSnap.data());
                declaredBool = declareSnap.data().declared;
                // if (declaredBool) {
                //     console.log(`Bet ${betId} - Cannot cancel as the game is already declared!`);
                //     alert("Cannot cancel bet as the game is already declared");
                //     return;
                // }
            } else {
                console.log(`Declare document for ${gamedate} / ${gameid} - No such document!`);
                return;
            }
        } catch (error) {
            console.error('Declare Check Failed due to:', error);
            alert("Declaration Check Failed - Can't Cancel Bet");
            return;
        }

        try {
            const userProfileQuery = query(collection(db, 'userProfile'), where('emailId', '==', email));
            const userProfileSnapshot = await getDocs(userProfileQuery);

            if (userProfileSnapshot.empty) {
                console.log(`No user profile found for email: ${email}`);
                return;
            }

            const userProfileDoc = userProfileSnapshot.docs[0];
            const userProfileData = userProfileDoc.data();
            console.log('User profile data:', userProfileData);
            const newWalletAmount = parseFloat(userProfileData.wallet) + parseFloat(amount);

            const userProfileRef = doc(db, 'userProfile', userProfileDoc.id);

            await updateDoc(userProfileRef, {
                wallet: newWalletAmount
            });

            await deleteDoc(doc(db, 'bets', betId));
            console.log(`Bet with ID ${betId} has been successfully deleted.`);

            setBetHistory(prevBetHistory => prevBetHistory.filter(bet => bet.id !== betId));
        } catch (error) {
            console.error('Error canceling bet:', error);
        }
    };


    const handleSort = (sortByField) => {
        const newSortOrder = sortBy === sortByField && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortBy(sortByField);
        setSortOrder(newSortOrder);

        const sortedBetHistory = sortBetHistory(betHistory, sortByField, newSortOrder);

        setBetHistory(sortedBetHistory);
    };

    // Function to convert 12-hour time format to 24-hour time format
    const convertTo24Hour = (time) => {
        if (!time) return time; // Return if time is not provided
        const [timePart, modifier] = time.split(' ');
        let [hours, minutes] = timePart.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }
        return `${hours.toString().padStart(2, '0')}:${minutes}`;
    };

    return (
        <div>
            <div className="inputSort-bethistory">
                <input
                    type="text"
                    placeholder="Enter Email"
                    value={emailFilter}
                    onChange={(e) => setEmailFilter(e.target.value)}
                />
                <button onClick={fetchBetHistory}>Search</button>
                <input
                    type="date"
                    placeholder="Select Date"
                    value={dateFilter.split('/').reverse().join('-')}
                    onChange={(e) => setDateFilter(formatDate(e.target.value))}
                />
            </div>

            <div className="table-wrapper">
                <table className="fl-table">
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('date')}>Date</th>
                            <th onClick={() => handleSort('time')}>Time</th>
                            <th>Game Name</th>
                            <th onClick={() => handleSort('email')}>Username</th>
                            <th>Number</th>
                            <th>Amount</th>
                            <th>Fatafat</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {betHistory.map(bet => (
                            <tr key={bet.id} onClick={() => { setEmailFilter(bet.email) }}>
                                <td style={{ border: '1px solid #000' }}>{bet.date}</td>
                                <td style={{ border: '1px solid #000' }}>{bet.time}</td>
                                <td style={{ border: '1px solid #000' }}>Bazi {bet.gameName}</td>
                                <td style={{ border: '1px solid #000' }}> {bet.email.replace("@gmail.com", "")}</td>
                                <td style={{ border: '1px solid #000' }}>{bet.number}</td>
                                <td style={{ border: '1px solid #000' }}>{bet.amount}</td>
                                <td style={{ border: '1px solid #000' }}>{bet.fatafatName == 'kalyanifatafat' ? 'Delhi Fatafat' : 'Kolkata Fatafat'}</td>
                                <td style={{ border: '1px solid #000' }}>
                                    <button onClick={() => handleCancelBet(bet.id, bet.email, bet.amount)}>Cancel Bet</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default BetHistory;
